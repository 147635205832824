import Link from 'next/link';
import AdsTxt from '@/components/AdsTxt';
import SettingsSearcher from '@/components/SettingsSearcher';
import HelpButtons from '@/components/HelpButtons';

const Header = () => {
  return (
    <div className="content-header">
      <div className="mg-r-30">
        <p className="new-top-title">All sites stats</p>
        <p className="new-top-title-name">
          <Link href="/" className="text-muted mr-1 outline-none mg-b-10">
            Home
          </Link>
        </p>
      </div>
      <div className="d-flex">
        {+window.innerWidth > 575 ? (
          <>
            <AdsTxt showWhenNoMissingLines={true} />
            <SettingsSearcher />
          </>
        ) : (
          <></>
        )}
        <HelpButtons />
      </div>
    </div>
  );
};

export default Header;
