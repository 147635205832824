import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateTitle } from '@/services/actions/session';

const useTitle = (title) => {
  const dispatch = useDispatch();

  const [pageTitle, setPageTitle] = useState(title);

  useEffect(() => {
    dispatch(updateTitle(pageTitle));
  }, [pageTitle]);

  return { title: pageTitle, setTitle: setPageTitle };
};

export default useTitle;
