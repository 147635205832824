import { Position } from 'evergreen-ui';
import { Popover2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

const HelpButtons = () => {
  const dropdownMenu = (
    <div className="vu-notifications-pop">
      <h4 className="vu-notifications-list__title">Latest Changes</h4>
      <ul className="vu-notifications-list" />
    </div>
  );

  return (
    <>
      <button className="vu-notifications-wrapper mr-3 tx-center align-self-center btn-sm--square btn-xs btn-ui-05 tx-medium pos-relative border-0 c-3A84FF">
        <Popover2
          content={dropdownMenu}
          position={Position.BOTTOM_RIGHT}
          minimal={true}
          border={0}
        >
          <span className="vu-notifications-pop__placeholder" />
        </Popover2>
        <FontAwesomeIcon className="fa-w-10" color="#A6ADC9" icon={faBell} />
      </button>
    </>
  );
};

export default HelpButtons;
