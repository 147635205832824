import PropTypes from 'prop-types';

import Header from '@/scenes/SitesList/components/Header';
import Footer from '@/layouts/Footer';

const HomeLayout = ({ children }) => (
  <div className="home-layout">
    <Header />
    <div className="content-body home-page-bg pd-t-20 firefoxScrollBar outline-none">
      {children}
    </div>
    <Footer />
  </div>
);

HomeLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  view: PropTypes.string,
};

export default HomeLayout;
