import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import AuthProtectedPage from '@/HOC/withAuthProtection';
import HomeLayout from '@/layouts/HomeLayout';
import AsideLayout from '@/layouts/AsideLayout';
import useTitle from '@/hooks/useTitle';

const SitesListScene = dynamic(() => import('@/scenes/SitesList'));

const SitesListPage = () => {
  useTitle('Vuukle Dashboard');

  useEffect(() => {
    const elem = document.getElementById('HW_badge_cont');

    if (elem) {
      elem.style.display = 'block';
      elem.style.visibility = 'visible';
      elem.style.zIndex = 99999;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementsByTagName('iframe');
      if (elem.length) {
        for (let i = 0; i < elem.length; i++) {
          if (
            elem[i].attributes &&
            elem[i].attributes.name &&
            elem[i].attributes.name.nodeValue === 'LPFrame' &&
            elem[i].parentElement.style.position === 'fixed'
          ) {
            elem[i].parentElement.style.position = 'relative';
          }
        }
      }
    }, 4000);
  });

  return (
    <>
      {/*<Head>*/}
      {/*<script*/}
      {/*  async*/}
      {/*  src="https://www.googletagmanager.com/gtag/js?id=AW-781841873"*/}
      {/*></script>*/}
      {/*<script*/}
      {/*  async*/}
      {/*  src="https://www.googletagmanager.com/gtag/js?id=UA-25104167-10"*/}
      {/*></script>*/}
      {/*<script>*/}
      {/*  {`window.dataLayer = window.dataLayer || [];*/}
      {/*            function gtag(){dataLayer.push(arguments);}*/}
      {/*            gtag('js', new Date());*/}
      {/*            gtag('config', 'AW-781841873');*/}
      {/*            gtag('event', 'conversion', {'send_to': 'AW-781841873/X1_9CJ_67PcCENHr5_QC'});*/}
      {/*            gtag('config', 'UA-25104167-10');*/}
      {/*            `}*/}
      {/*</script>*/}
      {/*</Head>*/}
      <div className="desktop-home-page">
        <AsideLayout withFirefoxScrollbar={false}>
          <HomeLayout view={'tablet'}>
            <SitesListScene view={'desktop'} />
          </HomeLayout>
        </AsideLayout>
      </div>
      <div className="tablet-home-page">
        <AsideLayout withFirefoxScrollbar={false}>
          <HomeLayout view={'tablet'}>
            <SitesListScene view={'tablet'} />
          </HomeLayout>
        </AsideLayout>
      </div>
    </>
  );
};

export async function getStaticProps() {
  return {
    props: {},
  };
}

export default AuthProtectedPage(SitesListPage);
